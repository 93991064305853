import { Box, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import sideImg from 'src/assets/svg/side.svg';

type Props = {
  contactSideButtonVisible: boolean;
};

const ContactSideButton = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Box
      display={props.contactSideButtonVisible ? { lg: 'unset', xs: 'none' } : 'none'}
      position="fixed"
      right={0}
      sx={{
        '&:hover': {
          opacity: [0.9, 0.85, 0.8],
        },
      }}
      top={400}
    >
      <Fab>
        <Box
          component="img"
          onClick={() => {
            navigate('/contact');
          }}
          src={sideImg}
          sx={{ cursor: 'pointer' }}
        />
      </Fab>
    </Box>
  );
};

export default ContactSideButton;
