import { Box, Container, Grid, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EndPoints } from 'src/cms/types/response';
import { client } from 'src/cms/utils';
import Layout from 'src/components/Layout';
import { PrimaryButton } from 'src/components/atoms/Button';

const StyledBox = styled(Box)({
  '& img': {
    width: '90%',
    height: 'auto',
  },
});

const NewsDetail = () => {
  const [post, setPost] = useState<EndPoints['get']['works'] | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      const res = await client.works._id(id).get({
        query: { fields: 'id,title,publishedAt,thumbnail,content' },
      });
      setPost(res.body);
    };
    fetchPost();
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();

  const id = params.id || '';

  const navigate = useNavigate();

  return (
    <Layout>
      <Container maxWidth="md" sx={{ paddingY: 4 }}>
        <Grid item sx={{ p: 4, display: 'flex', justifyContent: 'center' }} xs={12}>
          <Box component="img" src={post?.thumbnail.url} width="80%" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            {format(new Date(post?.publishedAt || '2022/10/1'), 'yyyy/MM/dd')}
          </Typography>
          <Typography color="text.primary" variant="h4">
            {post?.title}
          </Typography>
          <StyledBox dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
        </Grid>

        <Grid item textAlign="center" xs={12}>
          <PrimaryButton
            onClick={() => {
              navigate('/tech');
            }}
          >
            戻る
          </PrimaryButton>
        </Grid>
      </Container>
    </Layout>
  );
};

export default NewsDetail;
