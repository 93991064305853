import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import newsImg from 'src/assets/png/ph_news.png';
import { EndPoints } from 'src/cms/types/response';
import { client } from 'src/cms/utils';
import Layout from 'src/components/Layout';
import { PrimaryButton } from 'src/components/atoms/Button';
import { PageHead } from 'src/components/atoms/PageHead';

const News = () => {
  const [posts, setPosts] = useState<EndPoints['gets']['news'] | null>(null);
  const [view, setView] = useState(5);
  const [disable, setDisable] = useState({});
  const [viewMax, setViewMax] = useState(100);
  const incrementView = 5;

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await client.news.get({
        query: { limit: 100, fields: 'id,title,publishedAt,description' },
      });
      setPosts(res.body);
      setViewMax(res.body.totalCount);
    };
    fetchPosts();
    window.scrollTo(0, 0);
  }, []);

  const onClick = () => {
    console.log(viewMax);
    if (view + incrementView < viewMax) {
      setView(view + incrementView);
    } else {
      setView(viewMax);
      setDisable({ display: 'none' });
    }
    console.log(viewMax);
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <PageHead src={newsImg}>News</PageHead>
      <Container maxWidth="sm" sx={{ paddingY: 5, paddingX: 5 }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack divider={<Divider />}>
              {posts?.contents.slice(0, view).map((post) => (
                <Box
                  key={post.id}
                  onClick={() => {
                    navigate(`/news/detail/${post.id}`);
                  }}
                  sx={{ cursor: 'pointer', p: { xs: 2, md: 4 } }}
                >
                  <Typography variant="body1">
                    {format(new Date(post.publishedAt || '2014-01-24'), 'yyyy-MM-dd')}
                  </Typography>
                  <Typography variant="subtitle1">{post.title}</Typography>
                  <Typography component="p" noWrap sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
                    {post.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>

          <Grid item sx={{ pt: { xs: 2, md: 4 } }} textAlign="center" xs={12}>
            <PrimaryButton onClick={onClick} sx={disable}>
              続きを読み込む
            </PrimaryButton>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default News;
