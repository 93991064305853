import { Card, CardContent, CardMedia, Typography } from '@mui/material';

interface Props {
  title?: string;
  subtitle?: string;
  src?: string;
  children?: React.ReactNode;
  number?: number;
  titleColor?: string;
}

export const ResearchPanel: React.FC<Props> = (props) => {
  return (
    <Card
      sx={{
        maxWidth: 400,
        bgcolor: 'transparent',
        color: 'navy.main',
        border: 2,
        borderRadius: 3,
        boxShadow: 0,
      }}
    >
      <Typography
        bgcolor={'navy.main'}
        color={'cloud.main'}
        p={1}
        textAlign={'center'}
        variant="h6"
      >
        {props.number}
      </Typography>
      <CardMedia
        component="img"
        src={props.src}
        sx={{
          height: 80,
          objectFit: 'scale-down',
          pt: 2,
        }}
      ></CardMedia>

      <CardContent sx={{ m: 2 }}>
        <Typography
          color={'green.main'}
          component="div"
          fontWeight={900}
          textAlign={'center'}
          variant="h5"
        >
          {props.title}
        </Typography>
        <Typography
          component="div"
          sx={{
            pb: 2,
          }}
          textAlign={'center'}
          variant="subtitle1"
        >
          {props.subtitle}
        </Typography>
        {props.children}
      </CardContent>
    </Card>
  );
};
