import { createTheme, responsiveFontSizes } from '@mui/material';
import { jaJP } from '@mui/material/locale';

/** 使用する色 */
const COLOR = {
  NAVY: '#1B3B5C',
  RED: '#C91A4F',
  GREEN: '#62A11F',
  DARKGRAY: '#1A1A1A',
  CLOUD: '#FAFAFC',
};

const COLOR_LIGHT = {
  NAVY: '#8a9aac',
  RED: '#e18aa6',
  GREEN: '#aecd8d',
  DARKGRAY: '#8a8a8a',
};

/** MUIテーマ */
const theme = createTheme(
  {
    components: {
      MuiButton: {
        variants: [
          // プライマリ
          {
            props: { color: 'primary' },
            style: {
              color: COLOR.NAVY,
              borderColor: COLOR.NAVY,
              backgroundColor: COLOR.CLOUD,
              '&:hover': {
                color: COLOR.CLOUD,
                borderColor: COLOR.CLOUD,
                backgroundColor: COLOR_LIGHT.NAVY,
              },
              '&:disabled': {
                backgroundColor: COLOR.CLOUD,
              },
            },
          },
          // セカンダリ
          {
            props: { color: 'secondary' },
            style: {
              color: COLOR.CLOUD,
              backgroundColor: COLOR.RED,
              '&:hover': {
                color: COLOR.CLOUD,
                borderColor: COLOR.RED,
                backgroundColor: COLOR_LIGHT.RED,
              },
              '&:disabled': {
                backgroundColor: COLOR.CLOUD,
              },
            },
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: COLOR.CLOUD,
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: { color: 'darkgray' },
            style: {
              color: COLOR.DARKGRAY,
            },
          },
          {
            props: { color: 'navy' },
            style: {
              color: COLOR.NAVY,
            },
          },
          {
            props: { color: 'green' },
            style: {
              color: COLOR.GREEN,
            },
          },
          {
            props: { color: 'white' },
            style: {
              color: COLOR.CLOUD,
            },
          },
        ],
      },
      MuiDivider: {
        variants: [
          {
            props: { color: 'navy' },
            style: {
              // color: COLOR.NAVY,
              borderColor: COLOR.NAVY,
              backgroundColor: COLOR.NAVY,
            },
          },
        ],
      },
      MuiFab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },

    palette: {
      navy: {
        main: COLOR.NAVY,
        light: COLOR_LIGHT.NAVY,
      },
      red: {
        main: COLOR.RED,
        light: COLOR_LIGHT.RED,
      },
      green: {
        main: COLOR.GREEN,
        light: COLOR_LIGHT.GREEN,
      },
      darkgray: {
        main: COLOR.DARKGRAY,
        light: COLOR_LIGHT.DARKGRAY,
      },
      cloud: {
        main: COLOR.CLOUD,
      },
      background: {
        default: COLOR.CLOUD,
      },
    },
    typography: {
      fontFamily: ["'zen kaku gothic new'", 'sans-serif'].join(','),
      fontSize: 13,
      allVariants: {
        color: COLOR.DARKGRAY,
      },
      button: {
        textTransform: 'none',
      },
      body1: {
        lineHeight: 1.5,
        fontWeight: 400,
      },
      body2: {
        lineHeight: 1.5,
        fontWeight: 300,
      },
      h1: {
        lineHeight: 2,
        fontweight: 700,
      },
      h2: {
        lineHeight: 2,
        fontWeight: 700,
      },
      h3: {
        lineHeight: 2,
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h5: {
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h6: {
        fontWeight: 700,
        lineHeight: 1.5,
      },
      subtitle1: {
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },

    spacing: 8,
  },
  jaJP
);

export const projectTheme = responsiveFontSizes(theme);

declare module '@mui/material/styles' {
  interface Palette {
    cloud: Palette['primary'];
    darkgray: Palette['primary'];
    green: Palette['primary'];
    navy: Palette['primary'];
    red: Palette['primary'];
  }
  interface PaletteOptions {
    cloud: PaletteOptions['primary'];
    darkgray: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    navy: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
  }
}
