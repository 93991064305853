import { CssBaseline, ThemeProvider } from '@mui/material';

import { RouterConfig } from './RouterConfig';
import { projectTheme } from './themes/mui';

function App() {
  return (
    <ThemeProvider theme={projectTheme}>
      <CssBaseline />
      <RouterConfig />
    </ThemeProvider>
  );
}

export default App;
