import { Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import techImg from 'src/assets/png/ph_tech.png';
import techAcademic from 'src/assets/svg/academic.svg';
import techIssue from 'src/assets/svg/issue.svg';
import techSkill from 'src/assets/svg/skill.svg';
import { EndPoints } from 'src/cms/types';
import { client } from 'src/cms/utils';
import Layout from 'src/components/Layout';
import { PrimaryButton } from 'src/components/atoms/Button';
import { ContactBand } from 'src/components/atoms/Contact';
import ContactSideButton from 'src/components/atoms/ContactSideButton';
import { PageHead } from 'src/components/atoms/PageHead';
import { TechPanel } from 'src/components/atoms/TechPanel';
import { TitleGrid } from 'src/components/atoms/Title';

const Tech = () => {
  const [posts, setPosts] = useState<EndPoints['gets']['works'] | null>(null);
  const [contactSideButtonVisible, setContactSideButtonVisible] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await client.works.get({
        query: { fields: 'id,title,categories,description,thumbnail,publishedAt' },
      });
      setPosts(res.body);
    };
    fetchPosts();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const listener = () => {
      setContactSideButtonVisible(
        document.body.offsetHeight - (window.scrollY + window.innerHeight) >= 55
      );
    };
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const navigate = useNavigate();

  return (
    <Layout>
      <PageHead src={techImg}>Technologies</PageHead>
      <ContactSideButton contactSideButtonVisible={contactSideButtonVisible} />
      <Container maxWidth="md" sx={{ marginY: 8 }}>
        <Grid container rowSpacing={10}>
          <TitleGrid subTitle={'トーマスラボの特徴'} title={'Feature'} />
          <Grid item xs={12}>
            <Typography>
              &emsp;私たち、トーマスラボは様々なサービスの開発から運用を経験したメンバーで構成されています。
            </Typography>
            <Typography>
              クラウドを活用したシステムの提案から、大学や企業との研究開発まで幅広い経験と知識で、最適な提案を行います。
            </Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent={'space-evenly'}
            spacing={4}
            sx={{
              height: 'auto',
              alignItems: 'stretch',
            }}
            xs={12}
          >
            <Grid container item justifyContent={'center'} md={4} sm={6} xs={12}>
              <TechPanel
                src={techAcademic}
                subtitle="アカデミックな経験の活用"
                text="情報処理の基礎技術を初め、画像処理・情報圧縮技術・機械学習を活用した人工知能開発など、それぞれのメンバーの専門知識を生かした提案が可能です。また、大学の教授方に技術支援をいただきならが先端技術を提供することを得意としています。"
                title="データサイエンス"
              />
            </Grid>
            <Grid container item justifyContent={'center'} md={4} sm={6} xs={12}>
              <TechPanel
                src={techIssue}
                subtitle="ビジネス課題の整理と対策"
                text="お客様の実現したい事に対する課題を明確にしながら、課題のスケール応じて最適な解決方法を提案します。コストのかかるポイントを抑えながら、疑問や不安な部分を簡潔に分かりやすい説明を心がけています。"
                title="課題解決"
              />
            </Grid>
            <Grid container item justifyContent={'center'} md={4} sm={6} xs={12}>
              <TechPanel
                src={techSkill}
                subtitle="実装と運用"
                text="課題解決のためのアルゴリズムの実装や改善や、クラウドサービスを活用したシステムの構築・運用まで、弊社が一貫して担当をすることが可能です。IoT端末やスマートフォン、各種OSのコア機能など幅広い知識と技術で対応をいたします。"
                title="ITエンジニア力"
              />
            </Grid>
          </Grid>
          <TitleGrid subTitle={'これまでの実績'} title={'Works'} />
          <Grid item xs={12}>
            <Typography variant="body1">
              トーマスラボが開発や研究を行った、これまでの実績や技術を紹介します。
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            {posts ? (
              <Stack>
                {posts.contents.map((post) => (
                  <Grid container key={post.id} marginY={2} rowSpacing={5}>
                    <Grid item textAlign="center" xs={12}>
                      <Typography variant="h4">{post.title}</Typography>
                    </Grid>

                    <Grid container item justifyContent="center" xs={12}>
                      <Grid alignItems="center" container item justifyItems="start" xs={10}>
                        <Grid item>
                          <Typography>タグ：</Typography>
                        </Grid>

                        {post?.categories?.map((cate) => (
                          <Grid item key={cate.id}>
                            <Chip label={cate.name} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>

                    <Grid item textAlign="center" xs={12}>
                      <Box
                        component="img"
                        onClick={() => {
                          navigate(`/tech/detail/${post.id}`);
                        }}
                        src={post?.thumbnail.url}
                        sx={{ cursor: 'pointer' }}
                        width="80%"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        color="text.primary"
                        sx={{ whiteSpace: 'pre-wrap' }}
                        variant="body1"
                      >
                        {post.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider color="black" />
                    </Grid>

                    <Grid item textAlign="center" xs={12}>
                      <PrimaryButton
                        onClick={() => {
                          navigate(`/tech/detail/${post.id}`);
                        }}
                      >
                        詳細を見る
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            ) : (
              <Grid item textAlign="center" xs={12}>
                <Typography variant="h6">準備中</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <ContactBand
        onClick={() => {
          navigate('/contact');
        }}
      />
    </Layout>
  );
};

export default Tech;
