import { Divider, Grid, Typography, styled } from '@mui/material';
import { forwardRef } from 'react';

import isoImg from 'src/assets/svg/ISOmark.svg';
import mainWhiteImg from 'src/assets/svg/Main_white.svg';

const Image = styled('img')({
  objectFit: 'contain',
});

const FooterContent = () => {
  return (
    <>
      <Grid container padding={6} rowSpacing={2}>
        <Grid item md={2} xs={0} />
        <Grid
          columnSpacing={2}
          container
          item
          justifyContent={{ md: 'flex-end', xs: 'center' }}
          md={10}
          xs={12}
        >
          <Grid item>
            <Image alt="Logo" height="72" src={mainWhiteImg} />
          </Grid>
          <Grid item>
            <Typography color="white">株式会社トーマスラボ</Typography>
            <Typography color="white">〒820-0067</Typography>
            <Typography color="white">福岡県飯塚市680番地41 CIRD</Typography>
          </Grid>
          <Grid item>
            <Image alt="ISO" height="72" src={isoImg} />
          </Grid>
        </Grid>
        <Grid item md={2} xs={0} />
        <Grid item md={10} textAlign={{ md: 'right', xs: 'center' }} xs={12}>
          <Divider light sx={{ borderColor: 'white', width: '100%' }} />
          <Typography color="white" variant="caption">
            Copyright © 2022 ThomasLab inc. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const Footer = forwardRef<HTMLDivElement>(function Footer(_, ref) {
  return (
    <Grid container ref={ref} sx={{ backgroundColor: ({ palette }) => palette.navy.main }}>
      <Grid item lg={6} xs={0} />
      <Grid item lg={6} xs={12}>
        <FooterContent />
      </Grid>
    </Grid>
  );
});

export default Footer;
