import { memo, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Google Analytics監視コンポーネント */
export default memo(GoogleAnalyticsWatcher);
function GoogleAnalyticsWatcher() {
  const trackingUAId: string | undefined = process.env.REACT_APP_UA_MEASUREMENT_ID
  const trackingGAId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
  const location = useLocation();
  
  useLayoutEffect(() => {
    if (!window.gtag) return;
    if (!trackingUAId || !trackingGAId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
      );
      return;
    }
    return (window.gtag('config', trackingUAId, { page_path: location.pathname }),window.gtag('config', trackingGAId, { page_path: location.pathname }))

  }, [trackingUAId, trackingGAId ,location.pathname, location.search, location.hash]);

  return null;
}





