import { Button, ButtonProps, SxProps, Theme } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

export const PrimaryButton: React.FC<Props> = (props) => {
  const args: ButtonProps = {
    color: 'primary',
    variant: 'outlined',
    size: 'large',
  };

  return (
    <Button {...args} onClick={props.onClick} sx={props.sx} type={props.type}>
      {props.children}
    </Button>
  );
};

export const SecondaryButton: React.FC<Props> = (props) => {
  const args: ButtonProps = {
    color: 'secondary',
    variant: 'outlined',
    size: 'large',
  };

  return (
    <Button {...args} onClick={props.onClick} sx={props.sx} type={props.type}>
      {props.children}
    </Button>
  );
};
