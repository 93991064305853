import { Box, Button, SxProps, Typography } from '@mui/material';

interface Props {
  title?: string | undefined;
  children?: React.ReactNode;
  src?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

export const PanelButton: React.FC<Props> = (props) => {
  const sx: SxProps = {
    width: 320,
    height: 160,
    backgroundImage: `url(${props.src})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      opacity: [0.9, 0.8, 0.7],
    },
  };
  return (
    <Box component={Button} onClick={props.onClick} sx={sx}>
      <Box textAlign="center">
        <Typography sx={{ color: 'white' }} variant="h4">
          {props.title}
        </Typography>
        <Typography sx={{ color: 'white', fontWeight: 300 }} variant="h6">
          {props.children}
        </Typography>
      </Box>
    </Box>
  );
};
