import { Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

import companyImg from 'src/assets/png/ph_company.png';
import Layout from 'src/components/Layout';
import { PageHead } from 'src/components/atoms/PageHead';
import { TitleGrid } from 'src/components/atoms/Title';

const Company = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <PageHead src={companyImg}>Company</PageHead>
      <Container maxWidth="md" sx={{ marginY: 8 }}>
        <Grid container rowSpacing={8}>
          <TitleGrid subTitle={'会社概要'} title={'Profile'} />
          <Grid
            container
            item
            md={4}
            rowSpacing={4}
            sx={{ textAlign: { md: 'unset', xs: 'unset' } }}
            xs={12}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1">会社名</Typography>
              <Typography variant="body1">
                株式会社トーマスラボ
                <br /> &#40;ThomasLab Inc.&#41;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">代表者</Typography>
              <Typography variant="body1">坂本 時緒</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">設立年月日</Typography>
              <Typography variant="body1">2014年1月24日</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">所在地</Typography>
              <Typography variant="body1">〒820-0067</Typography>
              <Typography variant="body1">福岡県飯塚市川津680番地41CIRD</Typography>
            </Grid>
          </Grid>

          <Grid item md={8} minHeight={{ md: 'unset', xs: '420px' }} xs={12}>
            <iframe
              height="100%"
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.241253089066!2d130.66926031604754!3d33.65091024621798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35417f3f7ff0082b%3A0xf5bf22290b5c0f8d!2z5qCq5byP5Lya56S-44OI44O844Oe44K544Op44Oc!5e0!3m2!1sja!2sjp!4v1662618789533!5m2!1sja!2sjp"
              style={{ border: 0 }}
              width="100%"
            ></iframe>
          </Grid>

          <TitleGrid subTitle={'取引先・共同研究施設'} title={'Partners'} />

          <Grid item textAlign="center" xs={12}>
            <Typography variant="body2">順番は辞書順</Typography>
          </Grid>

          <Grid columnSpacing={2} item rowSpacing={2} xs={12}>
            <Typography textAlign={'center'} variant="h6">
              準備中
            </Typography>
            {/* <Grid item xs={4}>
              <Paper>LOGO1</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>LOGO2</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>LOGO3</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>LOGO4</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>LOGO5</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>LOGO6</Paper>
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Company;
