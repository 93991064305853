import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import arrowData from 'src/assets/lottie/arrow.json';
import animationData from 'src/assets/lottie/run.json';
import researchPanel from 'src/assets/png/top_research.png';
import techPanel from 'src/assets/png/top_tech.png';
import { EndPoints } from 'src/cms/types';
import { client } from 'src/cms/utils';
import Layout from 'src/components/Layout';
import { PrimaryButton } from 'src/components/atoms/Button';
import { ContactBand } from 'src/components/atoms/Contact';
import { PanelButton } from 'src/components/atoms/PanelButton';
import { TitleGrid } from 'src/components/atoms/Title';

const headerHeight = 64;

const Top = () => {
  const [posts, setPosts] = useState<EndPoints['gets']['news'] | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await client.news.get({
        query: { limit: 3, fields: 'id,publishedAt,title,description' },
      });
      setPosts(res.body);
    };
    fetchPosts();
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const handleClickToScorll = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const defaultOptions = {
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <Layout>
      <Box
        alignItems={'center'}
        justifyContent={'center'}
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
        sx={{ display: 'flex' }}
        width="100%"
      >
        <Box
          sx={{
            top: '10%',

            left: '5%',
            position: 'absolute',
          }}
        >
          <Typography variant="h3">世の中の当たり前を、</Typography>
          <Typography fontWeight={400} sx={{}} variant="h3">
            「ちょっとだけ」
          </Typography>
          <Typography variant="h3">変えてみる。</Typography>
        </Box>
        <Box sx={{ maxWidth: { sx: 300, sm: 600 }, display: 'flex' }}>
          <Lottie isClickToPauseDisabled options={defaultOptions} />
        </Box>
        <Box
          alignItems={'center'}
          onClick={handleClickToScorll}
          sx={{
            position: 'absolute',
            bottom: { xs: '30px', sm: '60px' },
            left: 'calc(50% -30px)',
            cursor: 'pointer',
          }}
          textAlign={'center'}
        >
          <Typography sx={{ pb: 2 }} variant="subtitle1">
            Scroll Down
          </Typography>
          <Lottie
            height={'50px'}
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData: arrowData,
            }}
            width={'50px'}
          />
        </Box>
      </Box>
      <Container maxWidth="md" sx={{ marginY: 8 }}>
        <Grid container ref={scrollRef} rowSpacing={8}>
          <TitleGrid subTitle="トーマスラボについて" title="About" />
          <Grid
            item
            sx={{
              textAlign: {
                xs: 'left',
                sm: 'center',
              },
            }}
            xs={12}
          >
            <Typography variant="body1">
              &emsp;私達は、テクノロジーを使って人々の意識を変え、社会的価値を生むことを目的とする組織です。
            </Typography>
            <Typography variant="body1">
              世の中に良い影響を与えるには、どんな「あたりまえ」をどう変えるべきか、日々研究しています。
            </Typography>
          </Grid>

          <Grid container item justifyContent="space-evenly" spacing={4} xs={12}>
            <Grid height="50%" item md={5}>
              <PanelButton
                onClick={() => {
                  navigate('/tech');
                }}
                src={techPanel}
                title={'Technologies'}
              >
                技術
              </PanelButton>
            </Grid>
            <Grid height="50%" item md={5}>
              <PanelButton
                onClick={() => {
                  navigate('/research');
                }}
                src={researchPanel}
                title={'Research'}
              >
                研究開発
              </PanelButton>
            </Grid>
          </Grid>

          <TitleGrid subTitle="お知らせ" title="News" />

          <Grid item textAlign="center" xs={12}>
            <Stack divider={<Divider />}>
              {posts?.contents.map((post) => (
                <Box key={post.id} marginY={2} sx={{ p: 2 }}>
                  <Typography variant="body1">
                    {format(new Date(post.publishedAt), 'yyyy/MM/dd')}
                  </Typography>
                  <Typography
                    onClick={() => {
                      navigate(`/news/detail/${post.id}`);
                    }}
                    sx={{ cursor: 'pointer' }}
                    variant="subtitle1"
                  >
                    {post.title}
                  </Typography>
                </Box>
              ))}
            </Stack>

            <PrimaryButton
              onClick={() => {
                navigate('/news');
              }}
            >
              一覧へ
            </PrimaryButton>
          </Grid>
        </Grid>
      </Container>
      <ContactBand
        onClick={() => {
          navigate('/contact');
        }}
      />
    </Layout>
  );
};

export default Top;
