import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import accountIcon from 'src/assets/icons/mdi_account-search.svg';
import caseIcon from 'src/assets/icons/mdi_briefcase-arrow-left-right.svg';
import cogIcon from 'src/assets/icons/mdi_cog.svg';
import headIcon from 'src/assets/icons/mdi_head-snowflake.svg';
import monitorIcon from 'src/assets/icons/mdi_monitor-dashboard.svg';
import researchImg from 'src/assets/png/ph_research.png';
import projectFlow from 'src/assets/svg/project_flow.svg';
import projectFlowMobile from 'src/assets/svg/project_flow_mobile.svg';
import Layout from 'src/components/Layout';
import { ContactBand } from 'src/components/atoms/Contact';
import ContactSideButton from 'src/components/atoms/ContactSideButton';
import { PageHead } from 'src/components/atoms/PageHead';
import { ResearchPanel } from 'src/components/atoms/ResearchPanel';
import { TitleGrid } from 'src/components/atoms/Title';
import { IsMobileSize } from 'src/lib/media';

const Research = () => {
  const [contactSideButtonVisible, setContactSideButtonVisible] = useState(true);

  useEffect(() => {
    const listener = () => {
      setContactSideButtonVisible(
        document.body.offsetHeight - (window.scrollY + window.innerHeight) >= 55
      );
    };
    window.scrollTo(0, 0);
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const navigate = useNavigate();

  return (
    <Layout>
      <PageHead src={researchImg}>Research</PageHead>
      <ContactSideButton contactSideButtonVisible={contactSideButtonVisible} />
      <Container maxWidth="md" sx={{ marginY: 8 }}>
        <Grid container rowSpacing={10}>
          <TitleGrid subTitle={'研究開発について'} title={'Research'} />

          <Grid item xs={12}>
            <Typography variant="body1">
              &emsp;機械学習の発展が進み、昨今では人工知能（AI）の活用が幅広い領域で、いままで以上の速さで進んでいます。
            </Typography>
            <Typography variant="body1">
              私たち、トーマスラボはAIが従来の人の判断や行動を代替するだけでなく、
              世の中にある新たな価値に気づく事ができる、新たな価値を創出する道具として、AIを活用する必要があると考えております。
            </Typography>
            <Typography variant="body1">
              これまでの主な取り組みとしては、
              様々なカメラや特殊なセンサーを活用して、2次元の情報から3次元の空間を認識する「コンピュータビジョン」の技術の応用や、
              ビッグデータのような高次元のデータから適切な方法データを取り出すことで、
              価値を見出す「ビッグデータ解析」を行ってきました。
              また、大学との連携をとりながら先進的な技術を、製品やサービスといった形で実現する研究開発に取り組んでいます。
            </Typography>
            <Typography variant="body1">
              データサイエンスで、これまでの&#8220;あたりまえ&#8221;
              を少しだけ変えて、身近な将来を創造したいと、日々考えています。
            </Typography>
          </Grid>

          {/* <Grid item textAlign="center" xs={12}>
            <Typography variant="h5">AI構築サービス</Typography>
            <Grid container item sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h4">「</Typography>
              <Typography color="green" variant="h4">
                EDISON&#40;エジソン&#41;
              </Typography>
              <Typography variant="h4">」について</Typography>
            </Grid>
          </Grid> */}

          <TitleGrid subTitle={'主なプロジェクトの流れ'} title={'Project flow'} />

          <Grid item xs={12}>
            <Typography>
              &emsp;トーマスラボでは、お客様の課題のヒアリングから、
              最適な解決法の実験・提案・システム開発・運用まで、すべて一貫してのご提案が可能です。
            </Typography>
            <Typography>
              一般的な解決策だけでなく、
              一歩踏みこんだ提案が出来るよう、様々な論文を調査し実装や移植をしながら柔軟に対応をいたします。
            </Typography>
            <Typography>
              また、AI・機械学習の専門知識を持ったデータサイエンティストやシステム開発のエンジニアが、お客様の課題解決をサポートいたします。
            </Typography>
          </Grid>

          <Grid item textAlign={'center'} xs={12}>
            <Box
              component="img"
              src={IsMobileSize() ? projectFlowMobile : projectFlow}
              sx={{
                objectFit: 'contain',
                width: '100%',
                pb: 6,
              }}
            />
            <Grid item textAlign={'left'} xs={12}>
              <Typography variant={'h6'}>相談・試作のフェーズ</Typography>
              <Typography>
                どうやったら解決できるか？を様々な方法を試しならが最適な方法を探ります。
              </Typography>
              <Typography sx={{ pt: 3 }} variant={'h6'}>
                運用・改良のフェーズ
              </Typography>
              <Typography>
                実際の運用の中で獲得した新たな情報を用いて、AIを業務に合わせて改良する方法を模索し、より賢いAIを構築します。
              </Typography>
            </Grid>
          </Grid>

          <Grid item textAlign="center" xs={12}>
            <Typography variant="h5">各フローの詳細</Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent="space-evenly"
            spacing={4}
            sx={{
              height: 'auto',
              alignItems: 'stretch',
            }}
            textAlign="center"
            xs={12}
          >
            <Grid container item justifyContent={'center'} md={6} sm={12}>
              <ResearchPanel number={1} src={accountIcon} subtitle="" title="ヒアリング・課題整理">
                <Typography textAlign={'left'} variant="body1">
                  AI等のアルゴリズムを構築する上で、最も大切なのがこの工程になります。
                  解決をしたい問題を、ヒアリングさせていただき、お客様のかかえる現状の問題を整理いたします。
                  その中で、実現可能性を精査させていただいたうえで、到達可能な目標をご相談させていただきます。
                </Typography>
              </ResearchPanel>
            </Grid>
            <Grid container item justifyContent={'center'} md={6} sm={12}>
              <ResearchPanel number={2} src={caseIcon} subtitle="" title="解決方法の提案・試作">
                <Typography textAlign={'left'} variant="body1">
                  設定をした目標に対しての解決方法や必要なデータなどをご提案いたします。
                  データには、業種や解決したいタスクなどによって様々な固有のドメインがあるため、データを適切に解析し、機械学習で利用可能な形にデータを修正を行います。
                  ある程度方針が決まったら、実際にAIアルゴリズムの試作を行います。
                </Typography>
              </ResearchPanel>
            </Grid>
            <Grid container item justifyContent={'center'} md={12} sm={12}>
              <ResearchPanel number={3} src={headIcon} subtitle="" title="アルゴリズムの構築">
                <Typography textAlign={'left'} variant="body1">
                  設定された目標の達成に向けて、アルゴリズムの選定を行います。
                  機械学習に必要なデータの特徴を定め、学習データを作成し、繰り返し、精度を高めるための工夫を模索します。
                  精度を高める方法は、アルゴリズムに応じて様々ですが、複数の手法の組み合わせ、パラメータの調整などを行います。
                </Typography>
              </ResearchPanel>
            </Grid>
            <Grid container item justifyContent={'center'} md={6} sm={12}>
              <ResearchPanel number={4} src={cogIcon} subtitle="目的の設定" title="システム開発">
                <Typography textAlign={'left'} variant="body1">
                  実際に現場で使用することを想定した、具体的なシステム要件を定義します。
                  要件に基づいたデータ基盤を構築することで、システムを作って終わりではなく、「AIが成長できる」システムを設計します。
                  また、システムの要件に応じてAIの推論速度の調整を行うフィッティングを行います。
                </Typography>
              </ResearchPanel>
            </Grid>
            <Grid container item justifyContent={'center'} md={6} sm={12}>
              <ResearchPanel number={5} src={monitorIcon} subtitle="目的の設定" title="運用">
                <Typography textAlign={'left'} variant="body1">
                  AIの推論や現在の状態を監視して、システムの運用をサポートします。
                  また、システムがデータを蓄積する場合に、追加のデータを使って、現在の課題に合わせてAIのアルゴリズムの見直しや、再学習を行う事ができます。
                  新たに、論文等で解析手法を発見した際には、採用を検討することも可能です。
                </Typography>
              </ResearchPanel>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ContactBand
        onClick={() => {
          navigate('/contact');
        }}
      />
    </Layout>
  );
};

export default Research;
