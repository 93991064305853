import { useEffect, useRef, useState } from 'react';

import Footer from 'src/components/organisms/Footer';
import Header from 'src/components/organisms/Header';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isStyle, setIsStyle] = useState(false);

  const headerElm = useRef<HTMLDivElement | null>(null);
  const footerElm = useRef<HTMLDivElement | null>(null);

  const headerHeight = headerElm?.current?.getBoundingClientRect()?.height || 0;
  const footerHeight = footerElm?.current?.getBoundingClientRect()?.height || 0;
  useEffect(() => {
    setIsStyle(true);
  }, []);

  return (
    <>
      <Header ref={headerElm} />
      <main
        style={
          isStyle
            ? {
                minHeight: window.innerHeight - Math.floor(headerHeight) - Math.floor(footerHeight),
              }
            : {}
        }
      >
        {children}
      </main>
      <Footer ref={footerElm} />
    </>
  );
};

export default Layout;
