import { Grid, Typography } from '@mui/material';

interface Props {
  title: string;
  subTitle: string;
}

export const TitleGrid: React.FC<Props> = (props) => {
  const headlineStyle = {
    mt: {
      xs: 2,
      md: 6,
    },
  };

  return (
    <Grid item textAlign="center" xs={12}>
      <Typography sx={headlineStyle} variant="h2">
        {props.title}
      </Typography>
      <Typography variant="h6">{props.subTitle}</Typography>
    </Grid>
  );
};
