import { Box, SxProps, Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  src?: string | undefined;
}

export const PageHead: React.FC<Props> = (props) => {
  const sx: SxProps = {
    width: '100%',
    height: {
      xs: 150,
      md: 200,
      lg: 250,
    },
    backgroundImage: `url(${props.src})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <Box sx={sx}>
      <Typography sx={{ color: 'white' }} variant="h2">
        {props.children}
      </Typography>
    </Box>
  );
};
