import { Box, SxProps, Typography } from '@mui/material';

import contactPagehead from 'src/assets/png/ph_contact.png';
import { SecondaryButton } from 'src/components/atoms/Button';

interface Props {
  onClick?: () => void;
}

export const ContactBand: React.FC<Props> = (props) => {
  const outerSx: SxProps = {
    width: '100%',
    minHeight: 200,
    height: {
      sm: 300,
      md: 350,
    },
    backgroundImage: `url(${contactPagehead})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const innerSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    height: '90%',
  };
  const textSx: SxProps = {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  };

  return (
    <Box sx={outerSx}>
      <Box sx={innerSx}>
        <Box pb={2} sx={textSx}>
          <Typography sx={{ color: 'white' }} variant="h3">
            {'Contact'}
          </Typography>
          <Typography sx={{ color: 'white' }} variant="body2">
            {'お問い合わせ'}
          </Typography>
        </Box>
        <SecondaryButton onClick={props.onClick}>こちらから</SecondaryButton>
      </Box>
    </Box>
  );
};
