import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

import Layout from 'src/components/Layout';

const securityText = `第１版

2022年9月1日
株式会社トーマスラボ
坂本時緒

私たち株式会社トーマスラボは、情報システムに関わる企画、設計、開発、保守運用業務の提供を通じて、お客様、社員とその家族などすべてのステークホルダーの期待に応え、社会に貢献することを使命と考えています。

当社の事業活動において、お客様からお預かりする個人情報を含む多くの情報資産を活用しており、すべてのステークホルダーの期待に応えるためには、これらの情報資産を保護する事は、経営上の最重要課題であると認識しています。

よって、私たちは、情報セキュリティ方針を策定し、本方針に基づいて、ISMS（情報セキュリティマネジメントシステム）を構築・運用し、当社を取り巻く環境の変化を踏まえ、継続的改善に全社を挙げて取り組むことをここに宣言します。

さらに、当社、以下のセキュリティ目的を設定し、この目的を達成するための諸施策を確実に実施します。

お客様との契約及び法的または規制要求事項を尊重し遵守する。
情報セキュリティ事故を未然に防止する。
万一情報セキュリティ事故が発生した場合、影響を最小限にする。

以上`;

const privacyText = `第1版

2022年9月1日
株式会社トーマスラボ
坂本時緒

1.法令等の遵守
当社（株式会社トーマスラボ）は、個人情報の取扱いについて、『個人情報の保護に関する法律』、『電気通信事業法』、その他個人情報保護関連法令及びガイドライン、および以下のプライバシーポリシーを遵守します。

2.個人情報の利用目的について
当社では、個人情報の取得に際しては、本プライバシーポリシー内または本ウェブサイト内において、あらかじめ利用目的をできる限り特定した上で公表します。また、公表した利用目的にしたがって個人情報を取り扱います。

3.利用目的の範囲内での利用
当社は、あらかじめ特定し公表した利用目的の達成に必要な範囲内でのみお客様の個人情報を取り扱います。

4.個人情報の安全管理措置について
お客様よりお預かりした個人情報は、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。

5.従業者の監督について
個人情報の取り扱いに関する内部規程類を明確化し、適切に個人情報を取り扱うよう従業者を監督いたします。

6.個人情報の第三者提供について
お客様よりお預かりした個人情報は、お客様本人の同意を得ずに第三者に提供することは、原則いたしません。

7.継続的改善
当社は、個人情報の取り扱いを適切なものとするよう、継続的な改善を実施します。

8.プライバシーポリシーの変更について
当社では、取得する個人情報の変更、利用目的の変更、またはその他プライバシーポリシーの変更を行う際は、当ページの変更をもって公表とさせていただきます。変更後のプライバシーポリシーはサイト上に改定日を表示した時点より効力を生じます。


（付則）本プライバシーポリシーは 2022年10月1日 制定及び実施するものとします。

以上`;

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Container maxWidth="md" sx={{ marginY: 4 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h3">
          情報セキュリティ方針
        </Typography>
        <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
          {securityText}
        </Typography>
        <Typography sx={{ pt: 10, fontWeight: 'bold' }} variant="h3">
          個人情報保護の方針
        </Typography>
        <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
          {privacyText}
        </Typography>
      </Container>
    </Layout>
  );
};

export default Privacy;
