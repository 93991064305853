import { Card, CardContent, CardMedia, Typography } from '@mui/material';

interface Props {
  title?: string;
  subtitle?: string;
  src?: string;
  text?: string;
}

export const TechPanel: React.FC<Props> = (props) => {
  return (
    <Card
      sx={{
        maxWidth: 350,
        bgcolor: 'cloud.main',
        borderTop: { xs: 0, sm: 2 },
        borderBottom: { xs: 0, sm: 2 },
        color: 'navy.main',
        borderRadius: 0,
        p: 2,
        boxShadow: 0,
      }}
    >
      <CardMedia
        component="img"
        image={props.src}
        sx={{
          height: 100,
          objectFit: 'scale-down',
        }}
      />
      <CardContent sx={{}}>
        <Typography color={'green.main'} component="div" textAlign={'center'} variant="h5">
          {props.title}
        </Typography>
        <Typography
          component="div"
          sx={{
            pb: 1,
          }}
          textAlign={'center'}
          variant="subtitle1"
        >
          {props.subtitle}
        </Typography>
        <Typography component="div" textAlign={'left'} variant="body1">
          {props.text}
        </Typography>
      </CardContent>
    </Card>
  );
};
