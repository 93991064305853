import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import contactImg from 'src/assets/png/ph_contact.png';
import Layout from 'src/components/Layout';
import { PrimaryButton } from 'src/components/atoms/Button';
import { PageHead } from 'src/components/atoms/PageHead';

const Contact = () => {
  const contactFormUrl = process.env.REACT_APP_CONTACT_FORM_URL ?? '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [postSuccess, setPostSuccess] = useState(false);
  const [postFailure, setPostFailure] = useState(false);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const rubiInputRef = useRef<HTMLInputElement>(null);
  const companyNameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const bodyInputRef = useRef<HTMLTextAreaElement>(null);
  const privacyPolicyConcentInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    console.log(privacyPolicyConcentInputRef.current);

    event.preventDefault();

    const params = new URLSearchParams();

    params.append('_field_1_name', nameInputRef.current?.value ?? '');
    params.append('_field_1_name_katakana', rubiInputRef.current?.value ?? '');
    params.append('_field_2', companyNameInputRef.current?.value ?? '');
    params.append('_field_4', emailInputRef.current?.value ?? '');
    params.append('_field_6', bodyInputRef.current?.value ?? '');
    params.append('_field_7', privacyPolicyConcentInputRef.current?.value ?? '');

    fetch(contactFormUrl, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: params,
    })
      .then(() => {
        setPostSuccess(true);
      })
      .catch(() => {
        setPostFailure(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <PageHead src={contactImg}>Contact</PageHead>
      <Container maxWidth="sm" sx={{ paddingY: 5 }}>
        <Typography sx={{ pb: 2 }} variant="subtitle1">
          弊社へのお問い合わせこちらのフォームから
        </Typography>

        <form onSubmit={handleSubmit}>
          <Stack spacing={5}>
            <TextField inputRef={nameInputRef} label="お名前" required type="text" />
            <TextField inputRef={rubiInputRef} label="お名前（フリガナ）" required type="text" />
            <TextField inputRef={companyNameInputRef} label="会社名" type="text" />
            <TextField inputRef={emailInputRef} label="メールアドレス" required type="email" />
            <TextField
              inputRef={bodyInputRef}
              label="お問い合わせ内容"
              multiline
              required
              rows={10}
            />
            <FormControlLabel
              control={<Checkbox required />}
              inputRef={privacyPolicyConcentInputRef}
              label={
                <Box sx={{ display: 'flex' }}>
                  <Link target="_blank" to="/Privacy">
                    個人情報保護
                  </Link>
                  <Typography>の方針に同意します。</Typography>
                </Box>
              }
            />
          </Stack>

          <Collapse in={postSuccess}>
            <Alert
              onClose={() => {
                setPostSuccess(false);
              }}
              severity="success"
              sx={{ width: '100%', marginY: 2 }}
            >
              送信が完了しました
            </Alert>
          </Collapse>

          <Collapse in={postFailure}>
            <Alert
              onClose={() => {
                setPostFailure(false);
              }}
              severity="error"
              sx={{ width: '100%', marginY: 2 }}
            >
              送信が失敗しました
            </Alert>
          </Collapse>

          <PrimaryButton type="submit">送信する</PrimaryButton>
        </form>
      </Container>
    </Layout>
  );
};

export default Contact;
