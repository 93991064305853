import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
} from '@mui/material';
import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import titleImg from 'src/assets/svg/Title.svg';

const pages = [
  { title: '会社概要', path: '/company' },
  { title: '技術', path: '/tech' },
  { title: '研究開発', path: '/research' },
  { title: 'ニュース', path: '/news' },
  { title: 'お問い合わせ', path: '/contact' },
  { title: 'プライバシーポリシー', path: '/privacy' },
];
const Header = forwardRef<HTMLDivElement>(function Header(_, ref) {
  /** Draserの開閉状態 */
  const [drawerOpen, setDrawerOpen] = useState(false);

  // /** Drawerを閉じる */
  // const closeDrawer = () => {
  //   setDrawerOpen(false);
  // };

  /** Drawerを切り替える */
  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen((drawerOpen) => !drawerOpen);
  };

  const navigate = useNavigate();

  return (
    <AppBar position="sticky" ref={ref} sx={{ paddingX: 4 }}>
      <Toolbar disableGutters>
        <Box display={{ md: 'none', xs: 'initial' }} width={0}>
          <IconButton onClick={toggleDrawer} size="medium">
            <MenuIcon />
          </IconButton>
          <Drawer onClose={toggleDrawer} open={drawerOpen}>
            <Box onClick={toggleDrawer} onKeyDown={toggleDrawer} role="presentation">
              <List>
                {pages.map((page) => (
                  <ListItem disablePadding key={page.title}>
                    <ListItemButton onClick={() => navigate(page.path)}>
                      {page.title}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Box>
        <Box display="flex" flexGrow={1} justifyContent={{ md: 'center', xs: 'center' }}>
          {/* <Link> */}
          <Box
            component="img"
            height={{ xs: 18, md: 24 }}
            onClick={() => navigate('/')}
            src={titleImg}
            sx={{ cursor: 'pointer' }}
            width="auto"
          />
          {/* </Link> */}
        </Box>
        <Grid
          columnSpacing={2}
          container
          display={{ md: 'flex', xs: 'none' }}
          flexGrow={1}
          justifyContent="flex-end"
        >
          {pages.map((page) => (
            <Grid item key={page.title}>
              <Button color="primary" onClick={() => navigate(page.path)}>
                {page.title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
