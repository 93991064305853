import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import * as Pages from 'src/pages';
import GoogleAnalyticsWatcher from './hooks/GoogleAnalyticsWatcher'

export const RouterConfig: React.FC = () => {

  return (
    <>
      <Router>
        <GoogleAnalyticsWatcher  />
        <Routes>
          <Route element={<Pages.Top />} index />
          <Route element={<Pages.Tech />} path="tech" />
          <Route element={<Pages.Company />} path="company" />
          <Route element={<Pages.Privacy />} path="privacy" />
          <Route element={<Pages.Contact />} path="contact" />
          <Route element={<Pages.News />} path="news" />
          <Route element={<Pages.Research />} path="research" />
          <Route element={<Pages.NewsDetail />} path="news/detail/:id" />
          <Route element={<Pages.WorksDetail />} path="tech/detail/:id" />
        </Routes>
      </Router>
    </>
  );
};
