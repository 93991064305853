import aspida from '@aspida/axios';
import axios from 'axios';

import api from '../apis/$api';

const fetchConfig: Required<Parameters<typeof aspida>>[1] = {
  baseURL: `${process.env.REACT_APP_API_URL ?? ''}`,
  headers: { 'X-MICROCMS-API-KEY': `${process.env.REACT_APP_MICROCMS_API_KEY ?? ''}` },
};

export const client = api(aspida(axios, fetchConfig));
